import React from 'react';
import classNames from 'classnames';
import contentBoxStyles from '../../../stylesheets/components/contentBox.module.scss';
import buttonStyles from '../../../stylesheets/components/buttons.module.scss';
import glimmerStyles from '../../../stylesheets/components/glimmer.module.scss';
import { Button } from '@mui/material';

export const PortalContentBoxGlimmer = () => {
  return (
    <div
      className={classNames(
        contentBoxStyles.contentBox,
        glimmerStyles.contentBox,
        glimmerStyles.glimmerElement
      )}
    ></div>
  );
};

export const PortalButtonGlimmer = () => {
  return (
    <Button
      className={classNames(
        buttonStyles.button,
        buttonStyles.modalButton,
        glimmerStyles.glimmerElement
      )}
    />
  );
};
